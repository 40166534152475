#search-bar {
  margin-top: 5rem;
  margin-bottom: 10rem;
  margin-inline: auto;
  width: 60%;
}

a {
  text-decoration: none;
}
