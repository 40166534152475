#grid {
  padding: 0 !important;
  margin-top: 2rem !important;
  max-width: 300px !important;
}

#card-div {
  max-width: 300px !important;
  object-fit: contain !important;
}
