#items-grid {
  margin-top: 3rem;
  margin-bottom: 5rem;
}

#item-grid {
  margin-bottom: 2rem !important;
  padding: 0 !important;
  max-width: 300px !important;
  height: 450px !important;
}

#product-div {
  height: 450px !important;
}
